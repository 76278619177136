<template>
  <div id="nav" class="flex bg-white">
    <div class="constrain">
      <div class="row-12 md:w-full flex flex-row-reverse md:flex-row justify-between md:justify-center flex-row">
        <div class="col-6 md:col-4 lg:col-3 flex justify-end md:justify-start">
          <Go :to="'/' + $store.state.router.locale" aria-label="Home">
            <Logo alt="Logo" label="Logo" />
          </Go>
        </div>
        <div class="col-6 md:col-8 lg:col-9">
          <div class="md:ml-auto flex items-center md:items-end h-full md:pl-5 xl:pl-6">
            <div class="relative flex items-center md:pb-4">
              <nav class="hidden md:flex">
                <transition-group name="appear">
                  <Go
                    v-for="link of navigation"
                    :key="link.uri"
                    :to="link.children[0]?.uri"
                    class="link"
                    :class="{ active: $store.state.router.page.meta?.translatedPaths[locale].includes(link.uri) }"
                    v-text="link.title"
                  />
                </transition-group>
              </nav>
              <Hamburger
                class="md:hidden"
                :class="{ active: mobile }"
                @click="toggle()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-wrapper">
      <div class="backdrop" :class="{ active: mobile }" />
      <transition-group name="appear">
        <div v-if="mobile" class="mobile-nav">
          <div class="navi">
            <ul
              v-for="(link, index) of navigation"
              :key="link.uri"
              class="firstLevelOutter"
            >
              <li
                :class="{ active: $store.state.router.page.meta?.translatedPaths[locale].includes(link.uri) }"
              >
                <Go
                  :to="link.uri"
                  class="firstLevel text-black link"
                  @click.prevent="toggleNavpoint(index)"
                  v-html="link.title"
                />
              </li>
              <ul
                ref="entries"
                class="secondLevelOutter flex flex-col"
                :class="{ open: $store.state.router.page.meta?.translatedPaths[locale].includes(link.uri) }"
              >
                <li
                  v-for="secondLink of link.children"
                  :key="secondLink.uri"
                >
                  <Go
                    :to="secondLink.uri"
                    class="link secondLevel"
                    :class="{ active: secondLink.uri === $store.state.router.page.meta?.translatedPaths[locale] }"
                    @click="toggle(true)"
                    v-text="secondLink.title"
                  />
                </li>
              </ul>
            </ul>
          </div>
          <div class="metaNavi">
            <ul>
              <li
                v-for="link of metaNavi"
                :key="link.uri"
                :class="{ active: link.uri === $store.state.router.page.meta?.translatedPaths[locale] }"
              >
                <Go
                  :to="link.uri"
                  class="link"
                  @click="toggle(true)"
                  v-text="link.title"
                />
              </li>
            </ul>
          </div>
          <div class="search">
            <ul>
              <li
                :class="{ active: '/de/info/suche' === $store.state.router.page.meta?.translatedPaths[locale] }"
              >
                <a
                  :href="searchLink"
                  class="link"
                  @click="toggle(true)"
                >
                  Suche
                </a>
              </li>
            </ul>
          </div>
          <Go class="kgs table" to="https://www.kgs-dielsdorf.ch/" target="_blank">
            <kgsLogo />
          </Go>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>

import kgsLogo from '@/assets/images/logo_kgs.svg';
import Logo from '@/assets/images/logo_at-sek.svg';
import Scrolllock from '@/utils/scrolllock';
import Hamburger from '../utils/Hamburger.vue';

export default {
  components: {
    Hamburger,
    kgsLogo,
    Logo,
  },
  setup() {
    return {
      lock: Scrolllock.lock,
      unlock: Scrolllock.unlock,
    };
  },
  data: () => ({
    mobile: false,
  }),
  computed: {
    locale() {
      return this.$store.state.router.layout?.locale;
    },
    navigation() {
      return this.$store.state.router?.layout?.navigation;
    },
    metaNavi() {
      return this.$store.state.router?.layout?.metaNavigation;
    },
    searchLink() {
      return this.$store.state.router?.layout?.suche;
    },
  },
  mounted() {
    const setHH = () => {
      const navElement = document.getElementById('nav');
      if (navElement) {
        document.documentElement.style.setProperty(
          '--hh',
          `${navElement.offsetHeight}px`,
        );
      }
    };

    const resizeObserver = new ResizeObserver(setHH);
    resizeObserver.observe(document.getElementById('nav'));

    window.addEventListener('DOMContentLoaded', setHH);
  },
  methods: {
    toggleNavpoint(index) {
      if (this.$refs.entries) {
        if (document.querySelectorAll('.open')[0]) {
          document.querySelectorAll('.open')[0].parentElement.querySelector('li').classList.remove('active');
          document.querySelectorAll('.open')[0].classList.remove('open');
        }
        this.$refs.entries[index].parentElement.querySelector('li').classList.add('active');
        this.$refs.entries[index].classList.add('open');
      }
    },
    toggle(close) {
      // eslint-disable-next-line no-param-reassign
      if (close === undefined) close = this.mobile;
      if (close) {
        this.mobile = false;
        setTimeout(() => this.unlock(), 400);
      } else {
        this.mobile = true;
        setTimeout(() => this.lock(), 400);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-wrapper {
  @screen md {
    display: none;
  }
}
#nav {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  @screen md {
    position: relative;
  }
  .constrain {
    padding-top: px(10);
    padding-bottom: px(10);
    @screen md {
      padding-top: px(25);
      padding-bottom: px(25);
    }
    @screen lg {
      padding-top: px(30);
      padding-bottom: px(30);
    }
    @screen xl {
      padding-top: px(30);
      padding-bottom: px(30);
    }
    > a {
      position: relative;
      z-index: 100;
    }
  }
  svg {
    width: px(215);
  }
  :deep(svg) {
    width: px(75);
    @screen md {
      width: px(85);
    }
    @screen lg {
      width: px(120);
    }
    @screen xl {
      width: px(150);
    }
    .logo_at-sek_svg__filling {
      transition: 0.25s all ease-out;
      fill: #171716;
    }

    &:hover {
      .logo_at-sek_svg__filling {
        fill: var(--blau);
      }
    }
  }
}

.link {
  text-transform: uppercase;
  margin-right: px(0);
  font-size: px(23); // 1.25rem
  &.secondLevel {
    font-size: px(20);
  }
  @screen sm {
    font-size: px(25);
    margin-right: px(15);
    &.secondLevel {
      font-size: px(21);
      margin-left: 3px;
    }
  }
  @screen md {
    font-size: max(1.55rem, 1.9vw);
  }
  @screen lg {
    font-size: 1.8rem;
  }
  @screen xl {
    font-size: 1.9rem;
  }

  &:hover {
    color: var(--tuerkis-dark);
  }

  &.active {
    color: var(--tuerkis-dark);
  }
  + .link {
    &:before {
      margin-right: px(15);
      content:"•";
      color: #000;
      display: inline-block;
    }
  }
}

/* MOBILE NAV */
.backdrop {
  content: "";
  position: fixed;
  z-index: 98;
  top: -30vh; // -30 for ios overscroll
  left: 0;
  width: 100%;
  height: 160vh; // +2*30 for ios overscroll
  transition: 0.5s ease;
  pointer-events: none;
  &.active {
    pointer-events: all;
  }
}

.mobile-nav {
  position: fixed;
  overflow-y: scroll;
  z-index: 99;
  display: flex;
  flex-direction: column;
  left: 0;
  width: 100%;
  top: var(--hh);
  height: calc(var(--doc-height) - var(--hh));
  background: var(--blau);
  padding: px(25);
  .kgs {
    margin-top: px(30);
    :deep(svg) {
      width: 120px;
      .logo_kgs_svg__filling {
        transition: 0.25s all ease-out;
        fill: transparent;
      }

      &:hover {
        .logo_kgs_svg__filling {
          fill: #FFF;
        }
      }
    }
  }
  .navi {
    border-bottom: px(2) solid #000;
    padding-bottom: 0.63rem;
    margin-bottom: 1.5rem;
  }
  a {
    text-decoration: none !important;
  }
  .firstLevelOutter {
    line-height: 1.2;
    list-style-type: disc;
    text-transform: uppercase;
    color: #FFF;
    padding-left: px(0);
    > li {
      word-wrap: break-word;
      margin-left: px(20);
      @screen sm {
        margin-left: px(24);
      }
      &:hover {
        &::marker {
          color: var(--tuerkis-dark);
        }
      }
      .link {
        font-size: px(28);
        @screen sm {
          font-size: px(33);
        }
        @screen md {
          font-size: px(38);
        }
      }
      &::marker {
        transition: 0.25s all ease-out;
        color: #000;
        font-size: px(23);
        @screen sm {
          font-size: px(28);
        }
      }
      &.active {
        margin-bottom: px(10);
        &::marker, a {
          color: var(--tuerkis-dark);
        }
      }
    }

    .link {
      font-weight: 500;
    }

    &:last-of-type{
      .secondLevelOutter {
        margin-bottom: 0;
        &.open {
          border-color: transparent;
        }
      }
    }
  }
  .secondLevelOutter {
    margin-bottom: px(15);
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.20s ease;
    list-style-type: disc;
    padding-left: 1.4rem;
    margin-left: px(-3);
    border-bottom: 1px solid;
    border-color: transparent;
    &.open {
      border-color: #FFF;
      max-height: 999px;
      transition: max-height 0.75s ease-out;
      margin-bottom: px(25);
    }
    @screen sm {
      padding-left: 1.5rem;
    }

    > li {
      word-wrap: break-word;
      margin-bottom: px(10);
      &::marker {
        color: #FFF;
        font-size: px(21);
        @screen sm {
          font-size: px(23);
        }
      }
    }
  }
  .metaNavi, .search {
    margin-bottom: 1.5rem;
    padding: 0 0 0.63rem 0;
    border-bottom: px(2) solid #000;
    ul {
      margin-left: 1.14rem;
      font-size: px(26.6667);
      line-height: 1.3;
      list-style-type: disc;
      text-transform: uppercase;
      color: #000;
      padding: 0.63rem 0 0 0;

      .link {
        font-weight: 450;
        font-size: px(20);
        @screen sm {
          font-size: px(22);
        }
        &:hover {
          li & {
            color: var(--color-active);
          }
        }
      }
      li {
        &::marker {
          transition: 0.25s all ease-out;
          font-size: px(20);
          @screen sm {
            font-size: px(22);
          }
        }
        &.active, &:hover {
          color: var(--color-active);
          &::marker {
            color: var(--color-active);
          }
        }
        transition: color 0.25s ease-out;
        + li {
          margin-top: 0.05rem;
        }
      }
    }
  }
  .search {
    border-color: var(--tuerkis-dark);
    li {
      &::marker, .link {
        color: var(--tuerkis-dark);
      }
      &:hover {
        &::marker, .link {
          color: #FFF !important;
        }
      }
    }
  }
}
</style>
