<template>

  <transition-group name="fade" :css="transition">

    <template v-for="part of parts" :key="part.id">

      <slot
        v-if="$slots[part.template]"
        :id="part.slug"
        :name="part.template"
        :part="part"
      />

      <component
        :is="part.template"
        v-else-if="$options.components[part.template]"
        :id="part.slug"
        :payload="part"
      />

      <div v-else class="constrain">
        <strong>Unregistered Part «{{ part.template }}»!</strong>
      </div>

    </template>

  </transition-group>

</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/structure/NotFound.vue')),
    '': defineAsyncComponent(() => import('@/components/structure/NotFound.vue')),
    Footer: defineAsyncComponent(() => import('@/components/structure/Footer.vue')),
    Home: defineAsyncComponent(() => import('@/components/structure/Home.vue')),
    Seite: defineAsyncComponent(() => import('@/components/structure/Seite.vue')),
    Unterseite: defineAsyncComponent(() => import('@/components/structure/Unterseite.vue')),
    Akkordion: defineAsyncComponent(() => import('@/components/parts/Akkordion.vue')),
    Inhalt: defineAsyncComponent(() => import('@/components/parts/Text.vue')),
    Text: defineAsyncComponent(() => import('@/components/parts/Text.vue')),
    Video: defineAsyncComponent(() => import('@/components/parts/Video.vue')),
    Projektgalerie: defineAsyncComponent(() => import('@/components/parts/JahresAkkordion.vue')),
    Veranstaltungen: defineAsyncComponent(() => import('@/components/parts/JahresAkkordion.vue')),
    Galerie: defineAsyncComponent(() => import('@/components/parts/Galerie.vue')),
    Suche: defineAsyncComponent(() => import('@/components/structure/Suche.vue')),
    Person: defineAsyncComponent(() => import('@/components/parts/Person.vue')),
    FilterAkkordion: defineAsyncComponent(() => import('@/components/parts/FilterAkkordion.vue')),
  },
  props: {
    parts: { type: Array, required: true },
    transition: { type: Boolean, default: true },
  },
};
</script>
